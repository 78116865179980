import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />

      <section className="text-center">
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-extrabold text-gray-900">
                Timed alerts
              </h2>
              <p className="mt-4 text-lg text-gray-500">
                Get notified when an expected event does not occur
              </p>
            </div>

            <dl className="mt-12 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 lg:grid-cols-3 lg:gap-x-8">
              <div className="relative">
                <dt>
                  <svg
                    className="absolute h-6 w-6 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                    Easy integration
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">
                  Send a POST (chirp) to us to reset your timer
                </dd>
              </div>
              <div className="relative">
                <dt>
                  <svg
                    className="absolute h-6 w-6 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                    Expiry
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">
                  If a chirp is not received in your configured interval,
                  we&apos;ll send you an alert to your chosen channel
                </dd>
              </div>

              <div className="relative">
                <dt>
                  <svg
                    className="absolute h-6 w-6 text-green-500"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  <p className="ml-9 text-lg leading-6 font-medium text-gray-900">
                    Alerts your way
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500">
                  <p>Receive an alert on your preferred channel</p>
                  <p>Slack</p>
                  <p>Webhook</p>
                </dd>
              </div>
            </dl>
          </div>
          <div className="mt-8 flex justify-center">
            <div className="inline-flex rounded-md shadow">
              <a
                href="https://join.slack.com/t/yarrasoft/shared_invite/zt-qqotq5z6-i7y8GeGqnWxjF1FFJkzUPQ"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Join us on slack
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default IndexPage;
